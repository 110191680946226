<template>
  <v-container id="searchbar">
    <v-row align="start" class="search-bar-container">
      <v-col cols="3">
        <v-select
          :items="categories"
          label="选择类型"
          v-model="selectedTypeModel"
          solo
        ></v-select>
      </v-col>

      <v-col cols="8">
        <v-text-field
          placeholder="请输入内容"
          solo
          v-model="question"
          @keydown.enter="search"
        ></v-text-field>
      </v-col>
      <v-col cols="1">
        <v-btn color="#FCA311" @click="search" fab width="50" height="50"
          ><v-icon color="#FFFFFF">mdi-search-web</v-icon></v-btn
        >
      </v-col>
    </v-row>
    <template>
      <v-snackbar v-model="snackbarList.searchSucc" top :timeout="3000">
        {{ snackbarList.searchSuccMsg }}
        <template v-slot:action="{ attrs }">
          <v-btn
            color="pink"
            text
            v-bind="attrs"
            @click="snackbarList.searchSucc = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
      <v-snackbar v-model="snackbarList.questionEmpty" top :timeout="3000">
        搜索内容不能为空
        <template v-slot:action="{ attrs }">
          <v-btn
            color="pink"
            text
            v-bind="attrs"
            @click="snackbarList.questionEmpty = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
      <v-snackbar v-model="snackbarList.typeEmpty" top :timeout="3000">
        请先选择要搜索的类型
        <template v-slot:action="{ attrs }">
          <v-btn
            color="pink"
            text
            v-bind="attrs"
            @click="snackbarList.typeEmpty = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </template>
    <v-overlay :absolute="true" :value="isLoading">
      <div>
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>
    </v-overlay>
  </v-container>
</template>

<script>
import axios from '@/service'
import { getToken } from '@/utils'
export default {
  name: 'SearchBar',
  props: {
    selectedType: String,
  },
  data() {
    return {
      question: null,
      res: null,
      categoriesList: {
        学生: 'Student',
        老师: 'Teacher',
        竞赛: 'Competition',
        团队: 'Group',
        居民: 'Human',
        公寓: 'House',
      },
      snackbarList: {
        searchSucc: false,
        questionEmpty: false,
        typeEmpty: false,
        searchSuccMsg: '',
      },
      graphData: {},
      selectedTypeModel: '',
      isLoading: false,
    }
  },
  watch: {
    selectedTypeModel(newValue) {
      this.$emit('changetype', this.categoriesList[newValue])
    },
  },
  computed: {
    categories() {
      return Object.keys(this.categoriesList)
    },
  },
  methods: {
    async search() {
      if (this.question) {
        if (!this.selectedTypeModel) {
          this.snackbarList.typeEmpty = true
          return
        }
        window.scrollTo(
          0,
          document.body.scrollHeight || document.documentElement.scrollHeight,
        )
        this.isLoading = true
        const {
          data: { data: getData },
        } = await axios.get(
          `/find/default/multi/${
            this.categoriesList[this.selectedTypeModel]
          }?name=${this.question}`,
          {
            headers: {
              Authorization: `${getToken()}`,
            },
          },
        )
        this.isLoading = false
        this.graphData = getData
        this.snackbarList.searchSucc = true
        console.log(getData)
        if (!Object.keys(getData).length) {
          this.snackbarList.searchSuccMsg = `未能检索出数据`
          return
        }
        this.snackbarList.searchSuccMsg = `搜索成功，检索出 ${this.graphData.RelationCount} 条关系`
        this.res = {
          ...getData,
          graphType: this.categoriesList[this.selectedTypeModel],
        }
        this.$emit('refrenshData', this.res)
      } else {
        this.snackbarList.questionEmpty = true
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.search-bar-container {
  position: absolute;
  left: 54px;
  top: 215px;
  z-index: 20;
}
.v-btn--fab.v-btn--contained {
  box-shadow: none !important;
}
</style>
