<template>
  <div class="graph-details-card" v-if="isShow">
    <v-card class="mx-auto" width="500" max-height="460">
      <v-card-title>
        <span style="user-select: none">{{ name }}</span>
        <v-spacer></v-spacer>
        <span @click="() => (isShow = false)">
          <v-icon medium>mdi-close</v-icon>
        </span>
      </v-card-title>

      <v-card-subtitle>
        <span style="user-select: none">简介</span>
      </v-card-subtitle>
      <v-card-text>
        <div class="summary-wrap">
          <!-- <div>{{ summaryState }}</div> -->
          <div v-if="summary.isable">
            <!-- <span style="user-select: none">{{ summary.content }}</span> -->
            <!-- <v-list class="ml-4">
              <v-list-item
                two-line
                v-for="(value, key) of summary.content"
                :key="key"
              >
                <v-list-item-content>
                  <v-list-item-title class="text-body-1">{{
                    key
                  }}</v-list-item-title>
                  <v-list-item-subtitle class="text-body-2">{{
                    value
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list> -->
            <template>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        Key
                      </th>
                      <th class="text-left">
                        Value
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(value, key) of summary.content" :key="key">
                      <td>{{ key }}</td>
                      <td>{{ value }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </template>
          </div>
          <div v-else><span style="user-select: none">暂无简介</span></div>
        </div>
      </v-card-text>
      <v-card-actions>
        <!-- <v-dialog v-model="isDialog" max-width="800">
          <v-card>
            <v-card-title class="headline lighten-2">
              <v-icon medium class="mr-2">mdi-eye-outline</v-icon>
              详细信息
            </v-card-title>
            <v-list class="ml-4">
              <v-list-item
                two-line
                v-for="(value, key) of centerList"
                :key="key"
              >
                <v-list-item-content>
                  <v-list-item-title class="text-body-1">{{
                    key
                  }}</v-list-item-title>
                  <v-list-item-subtitle class="text-body-2">{{
                    value
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="isDialog = false">
                关闭
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog> -->

        <v-btn
          color="#14213D"
          class="white--text ml-4"
          @click="() => this.$emit('spread')"
          v-if="showSpread"
        >
          扩展节点
          <v-icon right dark>
            mdi-call-split
          </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
export default {
  name: 'GraphDetails',
  props: {
    summary: Object,
    name: String,
    isShow: Boolean,
    centerList: Object,
    showSpread: Boolean,
  },
  setup() {
    const isDialog = ref(false)
    return {
      isDialog,
    }
  },
}
</script>

<style lang="scss" scoped>
.graph-details-card {
  position: absolute;
  left: 54px;
  top: 335px;
  z-index: 20;
}
.summary-wrap {
  overflow: scroll;
  max-height: 300px;
}
.detail-close {
  color: red;
  font-weight: 600;
}
</style>
