<template>
  <div>
    <template v-if="isSummaryShow">
      <v-col cols="12">
        <div id="graph"></div>
      </v-col>
    </template>
    <v-row class="graph-container">
      <v-overlay :absolute="true" :value="isLoading">
        <div class="mx-auto">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </div>
      </v-overlay>
    </v-row>
  </div>
</template>

<script>
import G6 from '@antv/g6'
import axios from '@/service'
import * as d3Force from 'd3-force'
import { getToken } from '@/utils'
export default {
  name: 'Graph',
  data() {
    return {
      graph: null,
      graphData: {
        nodes: [],
        edges: [],
      },
      nodeDsc: {},
      multiEdgeMap: {},
      nodesMap: {},
      isDialog: false,
      isLoading: false,
      spreadData: null,
      idMap: null,
      colorList: [
        '#FCA311',
        '#3366FF',
        '#55B519',
        '#DBAF24',
        '#FF632B',
        '#7eca9c',
        '#d8ac9c',
      ],
    }
  },
  props: {
    parentgraphData: {
      type: Object,
    },
    selectedType: String,
    emitSpread: Boolean,
  },
  computed: {
    summary: {
      get() {
        return this.$store.state.graphDetails.summary
      },
      set(value) {
        this.$store.commit('setSummary', value)
      },
    },
    isSummaryShow: {
      get() {
        return this.$store.state.graphDetails.isSummaryShow
      },
      set(value) {
        this.$store.commit('setisSummaryShow', value)
      },
    },
    isDetailsShow: {
      get() {
        return this.$store.state.graphDetails.isDetailsShow
      },
      set(value) {
        this.$store.commit('setisDetailsShow', value)
      },
    },
    clickedNode: {
      get() {
        return this.$store.state.graphDetails.clickedNode
      },
      set(value) {
        this.$store.commit('setclickNode', value)
      },
    },
    centerTitle: {
      get() {
        return this.$store.state.graphDetails.centerTitle
      },
      set(value) {
        this.$store.commit('setcenterTitle', value)
      },
    },
    centerList: {
      get() {
        return this.$store.state.graphDetails.centerList
      },
      set(value) {
        this.$store.commit('setcenterList', value)
      },
    },
    spreadList: {
      get() {
        return this.$store.state.graphDetails.spreadList
      },
      set(value) {
        this.$store.commit('setspreadList', value)
      },
    },
  },
  methods: {
    graphInit() {
      const toolbar = new G6.ToolBar({
        container: document.getElementById('toolbar'),
        getContent: () => {
          const outDiv = document.createElement('div')
          outDiv.style.padding = '4px 0'
          outDiv.style.width = '110px'
          outDiv.innerHTML = `<ul style="margin: 0; padding: 0">
              <li code="short"><span class="iconfont icon-find"></span></li>
              <li code="add"><span class="iconfont icon-Add"></span></li>
              <li code="remove"><span class="iconfont icon-Remove"></span></li>
            </ul>`
          return outDiv
        },
        handleClick: (code, graph) => {
          if (code === 'add') {
            graph.zoom(1.1, { x: 0, y: 0 })
          }
          if (code === 'remove') {
            graph.zoom(0.9, { x: 0, y: 0 })
          }
        },
      })
      const tooltip = new G6.Tooltip({
        getContent(e) {
          const outDiv = document.createElement('div')
          outDiv.innerHTML = `<p style="margin: 0; padding: 0">名称：${e.item.getModel()
            .label || e.item.getModel().id}</p>`
          return outDiv
        },
      })
      this.graph = new G6.Graph({
        container: 'graph',
        width: 1200,
        height: 700,
        fitView: true,
        fitViewPadding: [20, 40, 50, 20],
        defaultNode: {
          size: 85,
          style: {
            fill: '#FCA311',
            stroke: '#FCA311',
            lineWidth: 1,
            opacity: 0.9,
            cursor: 'pointer',
          },
          labelCfg: {
            style: {
              fill: '#FFFFFF',
              fontSize: 18,
            },
          },
        },
        defaultEdge: {
          color: '#000',
          autoRotate: true,
          type: 'line',
          labelCfg: {
            style: {
              fill: '#000',
              fontSize: 17,
            },
            autoRotate: true,
            refY: 15,
          },
        },
        nodeStateStyles: {
          active: {
            fill: '#fc7b11',
          },
          inactive: {
            fill: '#FCA311',
          },
        },
        edgeStateStyles: {
          active: {
            stroke: '#f58634',
            'text-shape': {
              fill: '#14213D',
            },
          },
          inactive: {
            'text-shape': {
              fill: '#fff',
            },
          },
        },
        modes: {
          default: [
            'drag-canvas',
            'zoom-canvas',
            'drag-node',
            {
              type: 'activate-relations',
              resetSelected: true,
              trigger: 'click',
            },
          ],
        },
        layout: {
          type: 'force',
        },
        plugins: [toolbar, tooltip],
        // plugins: [filterLens]
      })
      this.idMap = new Set()
    },
    refreshDragedNodePosition(e) {
      const model = e.item.get('model')
      model.fx = e.x
      model.fy = e.y
    },
    forceSimulation() {
      const edgeForce = d3Force
        .forceLink()
        .id(d => d.id)
        .links(this.graph.get('data').edges)
        .distance(230)
      return (
        d3Force
          .forceSimulation()
          .nodes(this.graph.get('data').nodes)
          .force(
            'center',
            d3Force.forceCenter(this.graph.center[0], this.graph.center[1]),
          )
          .force('charge', d3Force.forceManyBody().strength(-20))
          .force('link', edgeForce)
          .force(
            'collide',
            d3Force
              .forceCollide()
              .radius(function() {
                return 100
              })
              .iterations(100),
          )
          // .on('tick', () => {
          //   d3Force.tick()
          // })
          .alpha(0.3)
          .alphaDecay(0.028)
          .alphaMin(0.001)
      )
    },
    graphRefresh() {
      this.multiEdgeMap = {}
      this.nodesMap = {}
      this.clickedNode = null
      this.spreadList = {}
    },
    graphEventInit() {
      this.graph.on('node:click', e => {
        this.isDetailsShow = true
        this.clickedNode = e.item._cfg.id
        this.handleCenterShow(e.item._cfg.id)
      })
      this.graph.on('node:dragstart', e => {
        this.graph.layout()
        this.refreshDragedNodePosition(e)
      })

      this.graph.on('node:drag', e => {
        this.graph.get('layoutController').layoutMethod.execute()
        this.refreshDragedNodePosition(e)
      })

      this.graph.on('node:dragend', e => {
        e.item.get('model').fx = null
        e.item.get('model').fy = null
      })
    },
    handleNodeDsc(nodeData) {
      nodeData.forEach(v => {
        this.nodeDsc[v.id] = v
      })
    },
    async handleSpreadNodes(id) {
      this.isLoading = true
      const {
        data: { data: getData },
      } = await axios.get(
        `/find/default/multi/${this.nodesMap[id].nodeLabel}?id=${id}`,
        {
          headers: {
            Authorization: `${getToken()}`,
          },
        },
      )
      this.isLoading = false
      // console.log(getData)
      this.spreadData = getData
      this.spreadData.nodes.forEach(v => {
        if (v['中文名']) v.label = v['中文名']
        else v.label = v.name
        v.label = this.fittingString(v.label, 85, 18)
      })
      this.handleNodeDsc(this.spreadData.nodes)
      this.handleCenterShow(this.spreadData.center)
      this.spreadList[this.spreadData.center] = true
      let edgeList = []
      Object.keys(this.spreadData.edges).forEach(v => {
        edgeList = [...edgeList, ...this.spreadData.edges[v]]
      })
      this.spreadData.edges = edgeList
      this.spreadData.nodes.forEach(v => {
        this.nodesMap[v.id] = v
        if (!this.idMap.has(v.id)) {
          this.idMap.add(v.id)
        } else {
          return
        }
        this.graphData.nodes.push(v)
      })
      this.spreadData.edges.forEach(v => {
        if (!this.idMap.has(v.id)) {
          this.idMap.add(v.id)
        } else {
          return
        }
        this.graphData.edges.push(v)
      })
      this.graphData.nodes.forEach(v => {
        if (v.id === this.spreadData.center) {
          v.labelCfg = {
            style: {
              fill: '#fff',
              fontSize: 18,
            },
          }
          v.style = {
            fill: '#14213D',
            stroke: '#14213D',
            cursor: 'pointer',
          }
        }
      })
      const labelList = new Map()
      let lableIndex = 0
      this.graphData.nodes.forEach(v => {
        if (!labelList.has(v.nodeLabel)) {
          labelList.set(v.nodeLabel, lableIndex++)
        }
      })

      this.graphData.nodes.forEach(v => {
        v.style = {
          fill: this.colorList[labelList.get(v.nodeLabel) % 7],
          stroke: this.colorList[labelList.get(v.nodeLabel) % 7],
          cursor: 'pointer',
        }
        v.stateStyles = {
          active: {
            fill: this.colorList[labelList.get(v.nodeLabel) % 7],
            stroke: this.colorList[labelList.get(v.nodeLabel) % 7],
            'text-shape': {
              fill: '#000',
            },
          },
          inactive: {
            fill: this.colorList[labelList.get(v.nodeLabel) % 7],
            stroke: this.colorList[labelList.get(v.nodeLabel) % 7],
          },
        }
      })

      this.multiEdgeMap = {}
      this.handleGraphMap(this.graphData)
      this.handleMultiEdges()
      this.graph.data(this.graphData)
      this.graphEventInit()
      this.graph.updateLayout({
        nodeStrength: -25,
        linkDistance: 230,
        preventOverlap: true,
        nodeSpacing: 32,
        nodeSize: 100,
      })
      this.graph.render()
    },
    handleCenterShow(center) {
      this.centerTitle =
        this.nodeDsc[center]['中文名'] || this.nodeDsc[center].name
      this.centerList = {}
      // console.log(center)
      this.summary.isable = this.nodesMap[center].properties ? true : false
      // if (this.nodesMap[center].summary) {
      //   this.summary.content = this.nodesMap[center].summary
      //     .replaceAll('<a>', '')
      //     .replaceAll('<，a>', '')
      //   // console.log(this.summary.content)
      // }
      this.summary.content = this.nodesMap[center].properties
      Object.keys(this.nodeDsc[center])
        // .slice(0, 7)
        .forEach(v => {
          this.centerList[v] = this.nodeDsc[center][v]
        })
    },
    handleGraphMap(graphData) {
      graphData.edges.forEach((v, index) => {
        if (!v.label) v.label = v.type
        if (v.type) {
          delete v.type
        }
        if (!this.multiEdgeMap[v.target]) {
          this.multiEdgeMap[v.target] = {}
        }
        this.multiEdgeMap[v.target][v.source]
          ? this.multiEdgeMap[v.target][v.source].push(v)
          : (this.multiEdgeMap[v.target][v.source] = [v])
        this.multiEdgeMap[v.target][v.source][
          this.multiEdgeMap[v.target][v.source].length - 1
        ].index = index
      })
    },
    handleMultiEdges() {
      Object.keys(this.multiEdgeMap).forEach(v => {
        Object.keys(this.multiEdgeMap[v]).forEach(w => {
          const data = this.multiEdgeMap[v][w]
          if (data.length > 1) {
            // console.log(data)
            const tempOffset = Math.floor(100 / (data.length - 1))
            let nowOffset = -50
            data.forEach(i => {
              this.graphData.edges[i.index] = {
                ...this.graphData.edges[i.index],
                type: 'quadratic',
                curveOffset: nowOffset,
              }
              nowOffset += tempOffset
            })
          }
        })
      })
    },
    handleVideoGraph() {
      let edgeList = []
      Object.keys(this.graphData.edges).forEach(v => {
        edgeList = [...edgeList, ...this.graphData.edges[v]]
      })
      this.graphData.edges = edgeList
      this.handleGraphMap(this.graphData)

      const labelList = new Map()
      let lableIndex = 0

      this.graphData.nodes.forEach(v => {
        this.nodesMap[v.id] = v
        if (!this.idMap.has(v.id)) {
          this.idMap.add(v.id)
        }

        if (!labelList.has(v.nodeLabel)) {
          labelList.set(v.nodeLabel, lableIndex++)
        }
      })
      this.graphData.nodes.forEach(v => {
        v.style = {
          fill: this.colorList[labelList.get(v.nodeLabel) % 7],
          stroke: this.colorList[labelList.get(v.nodeLabel) % 7],
          cursor: 'pointer',
        }
        v.stateStyles = {
          active: {
            fill: this.colorList[labelList.get(v.nodeLabel) % 7],
            stroke: this.colorList[labelList.get(v.nodeLabel) % 7],
            'text-shape': {
              fill: '#000',
            },
          },
          inactive: {
            fill: this.colorList[labelList.get(v.nodeLabel) % 7],
            stroke: this.colorList[labelList.get(v.nodeLabel) % 7],
          },
        }
      })

      this.graphData.edges.forEach(v => {
        if (!this.idMap.has(v.id)) {
          this.idMap.add(v.id)
        }
      })
      this.handleMultiEdges()
      this.handleNodeDsc(this.graphData.nodes)
      this.handleCenterShow(this.graphData.center)
      this.graph.data(this.graphData)
      this.graphEventInit()
      this.graph.updateLayout({
        nodeStrength: -25,
        linkDistance: 230,
        preventOverlap: true,
        nodeSpacing: 32,
        nodeSize: 100,
        // forceSimulation: this.forceSimulation()
      })
      this.graph.render()
    },
    fittingString(str, maxWidth, fontSize) {
      const ellipsis = '...'
      const ellipsisLength = G6.Util.getTextSize(ellipsis, fontSize)[0]
      let currentWidth = 0
      let res = str
      const pattern = new RegExp('[\u4E00-\u9FA5]+')
      if (str)
        str.split('').forEach((letter, i) => {
          if (currentWidth > maxWidth - ellipsisLength) return
          if (pattern.test(letter)) {
            currentWidth += fontSize
          } else {
            currentWidth += G6.Util.getLetterWidth(letter, fontSize)
          }
          if (currentWidth > maxWidth - ellipsisLength) {
            res = `${str.substr(0, i)}${ellipsis}`
          }
        })
      return res
    },
    handleGraphUpdate(newV) {
      if (!this.graph) {
        this.graphInit()
      }
      this.graphRefresh()
      this.graph.center = newV.center
      this.spreadList[newV.center] = true
      this.clickedNode = newV.center
      this.graphData = newV
      // console.log(newV.center)
      this.graphData.nodes.forEach(v => {
        if (v['中文名']) v.label = v['中文名']
        else v.label = v.name
        v.label = this.fittingString(v.label, 50, 16)
        if (v.id === newV.center) {
          v.labelCfg = {
            style: {
              fill: '#fff',
              fontSize: 18,
            },
          }
          v.style = {
            fill: '#14213D',
            stroke: '#14213D',
            cursor: 'pointer',
          }
        }
        // if (v.nodeLabel === this.selectedType) {
        //   v.style = {
        //     fill: '#14213D',
        //     stroke: '#14213D',
        //     cursor: 'pointer'
        //   }
        // }
      })
      // switch (this.graphData.graphType) {
      //   case 'Video':
      //     this.handleVideoGraph()
      //     break
      //   case 'Person':
      //     this.handleVideoGraph()
      //     break
      //   default:
      //     break
      // }
      this.handleVideoGraph()
    },
  },
  watch: {
    parentgraphData(newV) {
      this.isSummaryShow = true
      if (newV) {
        this.$nextTick(() => {
          this.handleGraphUpdate(newV)
        })
      }
    },
    emitSpread() {
      this.handleSpreadNodes(this.clickedNode)
    },
  },
}
</script>

<style lang="scss">
.summary-wrap {
  max-height: 500px;
  overflow: auto;
}
#graph {
  position: relative;
}

.g6-component-toolbar {
  z-index: 10;
}
</style>
