<template>
  <div>
    <v-container>
      <v-row>
        <search-bar
          @refrenshData="refrenshData"
          id="searchbar"
          :selectedType="selectedType"
          @changetype="changeType"
        />
        <div id="toolbar"></div>
        <graph-details
          :summary="summary"
          :name="centerTitle"
          :isShow="isDetailsShow"
          :centerList="centerList"
          @spread="handleSpreadNodes"
          :showSpread="!spreadList[clickedNode]"
        />
      </v-row>
      <graph
        :parentgraphData="graphData"
        :selectedType="selectedType"
        :emitSpread="emitSpread"
        class="mt-6"
      />
    </v-container>
    <LoginDialog :dialog="dialog" />
  </div>
</template>

<script>
import Graph from './components/Graph.vue'
import SearchBar from './components/SearchBar.vue'
import GraphDetails from './components/GraphDetails.vue'
import LoginDialog from '@/views/components/LoginDialog.vue'
import { getToken } from '@/utils'
export default {
  name: 'Home',
  components: {
    Graph,
    SearchBar,
    GraphDetails,
    LoginDialog,
  },
  data() {
    return {
      graphData: undefined,
      selectedType: '',
      emitSpread: false,
      dialog: false,
    }
  },
  mounted() {
    if (!getToken()) {
      this.dialog = true
    }
  },
  computed: {
    summary() {
      return this.$store.state.graphDetails.summary
    },
    isSummaryShow() {
      return this.$store.state.graphDetails.isSummaryShow
    },
    isDetailsShow() {
      return this.$store.state.graphDetails.isDetailsShow
    },
    clickedNode() {
      return this.$store.state.graphDetails.clickedNode
    },
    centerTitle() {
      return this.$store.state.graphDetails.centerTitle
    },
    centerList() {
      return this.$store.state.graphDetails.centerList
    },
    spreadList() {
      return this.$store.state.graphDetails.spreadList
    },
  },
  methods: {
    refrenshData(res) {
      this.graphData = res
    },
    changeType(value) {
      this.selectedType = value
      // console.log(value)
    },
    handleSpreadNodes() {
      this.emitSpread = this.emitSpread ? false : true
    },
  },
}
</script>
<style lang="scss">
#toolbar {
  position: absolute;
  left: 54px;
  top: 290px;
  z-index: 20;
}
.v-text-field__details {
  display: none;
}
</style>
